
$(document).ready(function() {
    // NAV-TOGGLE
    $('.mobile_nav_switcher').bind('click', function(){
    	$('header ul li').slideToggle();
    	return false;
    });
});


$(window).resize(function() {
    if($(window).width() > 620){
    	$('header ul li').show();
    	$('header ul li').css('display', 'inline');
    } else {
    	$('header ul li').css('display', 'block');
    	$('header ul li').hide();
    }
    $('.no-touch .homeBtn').css('display', 'none');
});

var myLazyLoad = new LazyLoad({
    elements_selector: ".lazy"
});
